import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Section from './Section';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
      backgroundColor: 'white',
      padding: `0 ${theme.spacing(3)}px`,
      textAlign: 'center',
  },
  sectionContent: {
      background: 'linear-gradient(to right, #FF7E5F, #8A58E0)',
      borderRadius: '9px',
      padding: '42px',
      boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.1)',
      border: '0.3px solid #eee',
  },
  title: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#282A75',
    margin: '0 0 9px',
  },
  description: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 600,
    margin: '0 0 36px',
    color: 'white',
  },
  buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px',
  },
  buttonImage: {
      width: '150px',
  },
}));

const CtaSection = () => {
  const classes = useStyles();

  return (
    <Section>
      <Container className={classes.container}>
        <Box className={classes.sectionContent}>
          <Typography variant="h4" className={classes.title}>Unlock the universe in your pocket</Typography>
          <p className={classes.description}>Take the stars wherever you go! With the Istrologer app, you'll have instant access to personalized astrological insights, real-time celestial guidance, and dynamic forecasts tailored to your journey. Whether you're seeking clarity, planning your next move, or simply exploring the cosmos, Istrologer is your ultimate companion.</p>
          <div className={classes.buttonContainer}>
            <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer">
              <img src="/appstore.png" alt="Download on the App Store" className={classes.buttonImage} />
            </a>
            <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
              <img src="/googleplay.png" alt="Get it on Google Play" className={classes.buttonImage} />
            </a>
          </div>
        </Box>
      </Container>
    </Section>
  );
}

export default CtaSection;
