import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Link } from "./../util/router";

const useStyles = makeStyles((theme) => ({
  sticky: {
    backgroundColor: 'white',
    marginTop: "auto",
  },
  legalContainer: {
    backgroundColor: 'white',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(3),
    fontSize: "0.875rem",
    opacity: 0.6,
  },
  copyright: {
    color: "grey",
    backgroundColor: 'white',
    marginRight: "auto",
  },
  legalLinks: {
    "& a": {
      color: "grey",
      marginLeft: "0.8rem",
    },
  },
}));

function Footer(props) {
  const classes = useStyles();

  return (
    <Section
      bgColor={"white"}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={props.sticky && classes.sticky}
    >
      <Container>
        <Grid container={true} justifyContent="space-between" alignItems="center">
          <Grid item={true} xs={12}>
            <div className={classes.legalContainer}>
              <div className={classes.copyright}>
                {props.copyright}
              </div>
              <div className={classes.legalLinks}>
                <LinkMui component={Link} to="/legal/terms-of-service">
                  Terms
                </LinkMui>
                <LinkMui component={Link} to="/legal/privacy-policy">
                  Privacy
                </LinkMui>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default Footer;
