import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import capitalize from "@material-ui/core/utils/capitalize";
import BackgroundImage from "./BackgroundImage";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "& > .MuiContainer-root": {
      position: "relative",
    },
  },
  // Remove the color classes that set border between sections
  ...[
    ["white", theme.palette.background.default],
    ["light", emphasize(theme.palette.background.default, 0.03)],
    ["primary", theme.palette.primary.main],
    ["secondary", theme.palette.secondary.main],
  ].reduce((acc, [name, value]) => {
    acc[`color${capitalize(name)}`] = {
      backgroundColor: value,
      color: theme.palette.getContrastText(value),
      // Remove the border between sections
      // [`& + &`]: {
      //   borderTop: `1px solid ${emphasize(value, 0.09)}`,
      // },
    };
    return acc;
  }, {}),
  colorInherit: {
    color: "inherit",
  },
  colorTransparent: {
    backgroundColor: "white",
    color: "inherit",
  },
}));

function Section(props) {
  const classes = useStyles();

  const {
    bgColor = "white",
    bgImage,
    bgImageOpacity,
    size = "normal",
    className,
    children,
    ...otherProps
  } = props;

  const verticalPadding = {
    normal: { xs: 6 },
    medium: { xs: 6, sm: 10 },
    large: { xs: 6, sm: 20 },
    auto: 0,
  }[size];

  return (
    <Box
      component="section"
      py={verticalPadding}
      className={
        classes.root +
        ` ${classes[`color${capitalize(bgColor)}`]}` +
        (className ? ` ${className}` : "")
      }
      {...otherProps}
    >
      {bgImage && <BackgroundImage image={bgImage} opacity={bgImageOpacity} />}
      {children}
    </Box>
  );
}

export default Section;
