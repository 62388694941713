import { useLocation } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { scroller } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `0 ${theme.spacing(3)}px`,
    backgroundColor: 'white',
    borderRadius: '9px',
    textAlign: 'left',
  },
  sectionContent: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '9px',
    overflow: 'hidden',
    padding: '21px',
  },
  textContainer: {
    position: 'relative',
    zIndex: 1,
    flex: 1,
    padding: theme.spacing(3),
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0,
    background: 'radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%), url("/hero.png") no-repeat center center',
    backgroundSize: 'cover',
  },
  image: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    width: '42px',
    height: 'auto',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  title: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#FDB50E',
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 600,
    color: '#FFFFFF',
    marginBottom: theme.spacing(1),
  },
  description: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 600,
    marginBottom: theme.spacing(9),
    color: '#FFFFFF',
  },
  button: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 600,
    backgroundColor: '#695DFF',
    color: 'white',
    '&:hover': {
      backgroundColor: '#282A75',
    },
    padding: theme.spacing(1, 3),
    textTransform: 'none',
  },
  section: {
    backgroundColor: 'white',
    margin: 0,
    padding: 0,
  },
}));

const HeroSection = () => {
  const classes = useStyles();
  const location = useLocation();

  const handleLinkClick = (sectionId) => {
    const offset = -87;
    if (location.pathname !== "/") {
      window.location.href = `/#${sectionId}`;
    } else {
      scroller.scrollTo(sectionId, {
        smooth: true,
        duration: 500,
        offset: offset,
      });
    }
  };

  return (
    <Section id="hero-section" className={classes.section}>
      <Container >
        <Box className={classes.sectionContent}>
          <Box className={classes.textContainer}>
            <Typography variant="h2" className={classes.title}>
              AI-driven astrology tailored just for you
            </Typography>
            <Typography variant="h6" className={classes.subtitle}>
              Navigate your life with personalized astrological insights
            </Typography>
            <Typography variant="body1" className={classes.description}/>
            <Button
              onClick={() => handleLinkClick("connect-section")}
              variant="contained"
              className={classes.button}
            >
              Start your cosmic journey
            </Button>
          </Box>
          <Box className={classes.imageContainer}/>
        </Box>
      </Container>
    </Section>
  );
}

export default HeroSection;
