import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: `0 ${theme.spacing(3)}px`,
  },
  sectionHeader: {
    backgroundColor: 'white',
    marginBottom: theme.spacing(6),
  },
  sectionTitle: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#282A75',
    marginBottom: theme.spacing(6),
  },
  featureImage: {
    backgroundColor: 'white',
    width: '100px', // Adjust size as needed
    height: '100px',
  },
  title1: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.8rem',
    fontWeight: 500,
    color: '#8A58E0',
    marginBottom: theme.spacing(3),
  },
  text: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 300,
    color: '#000000',
    marginBottom: theme.spacing(0),
  },
  section: {
    backgroundColor: 'white',
    margin: 0,
    padding: 69,
  },
}));

function BenefitsSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Personalized Cosmic Insights",
      subtitle: "Your astrological journey starts with you. Istrologer delves deep into your unique birth chart, offering tailored guidance that resonates with your personal story. From understanding your strengths to uncovering opportunities, every insight is as unique as the stars themselves.",
      image: "/chart.png",
    },
    {
      title: "Astrology at Your Fingertips",
      subtitle: "Gone are the days of waiting for appointments with astrologers. With Istrologer, you have a cosmic companion available 24/7. Whether you're curious about today’s planetary alignments or need clarity on a pressing decision, the stars are just a tap away.",
      image: "/consultation.png",
    },
    {
      title: "Navigate Life with Confidence",
      subtitle: "Life’s challenges are easier to tackle when you have guidance. Istrologer provides clarity on relationships, career choices, and personal growth by interpreting the stars’ messages for your unique circumstances. Feel empowered to make decisions that align with your cosmic rhythm.",
      image: "/consultation.png",
    },
    {
      title: "Dynamic Astro Forecasts",
      subtitle: "Stay one step ahead of the universe. Istrologer delivers daily, weekly, and monthly forecasts tailored to your chart. With insights into upcoming opportunities and challenges, you can plan your life in harmony with the celestial calendar.",
      image: "/forecast.png",
    },
  ];

  return (
    <Section id="benefits-section" className={classes.section}>
      <Container className={classes.container}>
        <Box textAlign="center">
          <Typography className={classes.sectionTitle}>Unlock the power of the stars</Typography>
        </Box>
        <Grid container={true} justifyContent="center" spacing={6}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={3} key={index}>
              <Box textAlign="center">
                <Box>
                  <img src={item.image} alt={item.title} className={classes.featureImage} />
                </Box>
                <Typography variant="h5" gutterBottom={true} className={classes.title1}>
                  {item.title}
                </Typography>
                <Box mt={3}>
                  <Typography variant="subtitle1" className={classes.text}>{item.subtitle}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default BenefitsSection;
