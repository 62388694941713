import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme?.palette?.background?.default || 'white'};
    font-family: 'Onest', sans-serif;
    text-transform: none;
    color-scheme: ${({ theme }) => (theme?.palette?.type === 'dark' ? 'dark' : 'light')};
  }

  button, a, p, h1, h2, h3, h4, h5, h6, li {
    font-family: 'Onest', sans-serif;
    text-transform: none;
    color: ${({ theme }) => theme?.palette?.text?.primary || 'black'};
  }
`;

export default GlobalStyle;
