import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: `0 ${theme.spacing(3)}px`,
  },
  sectionTitle: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#282A75',
    marginBottom: theme.spacing(6),
  },
  media: {
    height: 231,
    borderRadius: "9px",
  },
  card: {
    backgroundColor: "#282A75",
    color: "white",
    padding: theme.spacing(3),
    borderRadius: "9px",
  },
  title1: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#FDB50E',
  },
  title2: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#FDB50E',
    marginBottom: theme.spacing(3),
  },
  body: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 300,
    color: 'white',
  },
  step: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 600,
    color: '#FDB50E',
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  section: {
    backgroundColor: 'white',
    margin: 0,
    padding: 36,
  },
}));

function HowSection(props) {
  const classes = useStyles();

  const items = [
    {
      step: "Step 1",
      image: "/1.png",
      title1: "Choose",
      title2: "your plan",
      body: "Select a subscription that aligns with your needs",
    },
    {
      step: "Step 2",
      image: "/2.png",
      title1: "Create",
      title2: "your profile",
      body: "Enter your birth details to receive accurate readings",
    },
    {
      step: "Step 3",
      image: "/3.png",
      title1: "Explore",
      title2: "insights",
      body: "Dive into personalized astro guidance and grow",
    },
  ];

  return (
    <Section id="how-section" className={classes.section}>
      <Container  className={classes.container}>
        <Box textAlign="center">
          <Typography variant="h2" className={classes.sectionTitle}>How Istrologer works</Typography>
        </Box>
        <Grid container={true} justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={6} lg={4} key={index}>
              <Card className={classes.card}>
                <CardActionArea>
                  <Typography variant="h6" component="h3" className={classes.step}>
                    {item.step}
                  </Typography>
                  <Grid container>
                    <Grid item xs={7}>
                      <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom={false} className={classes.title1}>
                          {item.title1}
                        </Typography>
                        <Typography variant="h5" component="h2" gutterBottom={true} className={classes.title2}>
                          {item.title2}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.body}>
                          {item.body}
                        </Typography>
                      </CardContent>
                    </Grid>
                    <Grid item xs={5}>
                      <CardMedia
                        image={item.image}
                        title={item.name}
                        className={classes.media}
                      />
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default HowSection;
