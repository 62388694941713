import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import HowSection from "./../components/HowSection";
import BenefitsSection from "./../components/BenefitsSection";
import CtaSection from "./../components/CtaSection";
import TopSection from "./../components/TopSection";
import ContactSection from "./../components/ContactSection";
import InfoSection from "./../components/InfoSection";
import HelpSection from "./../components/HelpSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="white"
        size="large"
        bgImage="/hero.png"
        bgImageOpacity={0.3}
        title=""
        subtitle=""
        buttonText=""
        buttonColor="default"
        buttonPath="/pricing"
      />
      <FeaturesSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      <HowSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      />
      <BenefitsSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      <CtaSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      <ContactSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />
      <InfoSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <HelpSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
      <TopSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle="Or please get started now even if you don't feel ready. We're just really going to need you to get started. Click it. Click the button."
        buttonText="Back to Top"
        buttonColor="primary"
        buttonPath="/pricing"
      />
    </>
  );
}

export default IndexPage;
