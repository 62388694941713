import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: `0 ${theme.spacing(3)}px`,
    textAlign: 'center',
  },
  sectionContent: {
    background: 'linear-gradient(135deg, #9b59b6, #e74c3c)',
    borderRadius: '9px',
    padding: '42px',
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.1)',
    border: '0.3px solid #eee',
  },
  infoTitle: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#282A75',
    margin: '0 0 36px',
  },
  infoSubtitle: {
    color: 'grey',
    marginBottom: theme.spacing(1),
  },
  infoText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '1.2em',
  },
  infoBox: {
    backgroundColor: 'white',
    borderRadius: '9px',
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    minWidth: '250px',
  },
  containerFlex: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  section: {
    backgroundColor: 'white',
    margin: 0,
    padding: 48,
  },
}));

const InfoSection = () => {
  const classes = useStyles();

  return (
    <Section className={classes.section}>
      <Container className={classes.container}>
        <Box className={classes.sectionContent}>
          <Typography variant="h4" className={classes.infoTitle}>
            Contact Information
          </Typography>
          <Box className={classes.containerFlex}>
            <Box className={classes.infoBox}>
              <Typography className={classes.infoSubtitle}>
                E-mail
              </Typography>
              <Typography className={classes.infoText}>
                istrologer@gmail.com
              </Typography>
            </Box>
            <Box className={classes.infoBox}>
              <Typography className={classes.infoSubtitle}>
                Phone
              </Typography>
              <Typography className={classes.infoText}>
                +1 (954) 406-7304
              </Typography>
            </Box>
            <Box className={classes.infoBox}>
              <Typography className={classes.infoSubtitle}>
                Telegram
              </Typography>
              <Typography className={classes.infoText}>
                @istrologer_bot
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Section>
  );
};

export default InfoSection;
