import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { ThemeProvider } from "./../util/theme";
import GlobalStyle from './../GlobalStyle';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: `0 ${theme.spacing(3)}px`,
  },
  sectionTitle: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#282A75',
    marginBottom: theme.spacing(6),
  },
  question: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 600,
    color: '#000000',
  },
  answer: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 300,
    color: '#000000',
  },
}));

function HelpSection(props) {
  const classes = useStyles();
  
  const items = [{
    question: "How does Istrologer work?",
    answer: "Istrologer uses advanced AI combined with traditional astrological wisdom to create personalized insights based on your birth chart. Enter your birth details (date, time, and place), and Istrologer crafts predictions, compatibility readings, and advice tailored to you. Think of it as your pocket astrologer, always ready to guide you!",
  }, {
    question: "Do I need to know my exact birth time?",
    answer: "While exact birth time provides the most accurate chart (especially for your rising sign and houses), you can estimate or use noon if you're unsure. Noon offers balanced predictions without major inaccuracies. Tooltips within the app will help guide you.",
  }, {
    question: "What makes Istrologer different from other astrology apps?",
    answer: "Unlike generic horoscope apps, Istrologer delivers deeply personalized insights. Our AI interprets complex birth charts and provides dynamic forecasts, real-time advice, and interactive features like challenges—all tailored to your unique cosmic blueprint.",
  }, {
    question: "Is Istrologer for beginners or astrology enthusiasts?",
    answer: "Both! Whether you're a stargazing novice or a seasoned astrologer, Istrologer adapts to your knowledge level. Beginners get clear, simple guidance, while enthusiasts can dive deeper into advanced astrological aspects.",
  }, {
    question: "What happens during a Mercury Retrograde?",
    answer: "Ah, the infamous Mercury Retrograde! Istrologer keeps you ahead of the cosmic chaos with timely updates. Whether it’s tech troubles, miscommunications, or revisiting old flames, we’ll help you navigate these quirky planetary hiccups with confidence and clarity.",
  }, {
    question: "Can Istrologer predict my future?",
    answer: "While Istrologer can’t predict lottery numbers (we wish!), it helps you align with the energies of the universe. It highlights opportunities, challenges, and the best times for growth, relationships, and decision-making based on your chart.",
  }, {
    question: "Can Istrologer help me find my soulmate?",
    answer: "While we can't guarantee a soulmate delivery service, Istrologer can guide you to the stars that align best with yours! Use our celestial advice to strengthen relationships, meet new connections, and maybe, just maybe, find that cosmic spark. Love is written in the stars — you just have to decode it!",
  }, {
    question: "Is my data safe with Istrologer?",
    answer: "Absolutely! We take data security seriously. Your information is stored securely and used solely to provide accurate readings. We comply with global privacy regulations, so your cosmic details are in good hands.",
  }, {
    question: "Can I cancel my subscription anytime?",
    answer: "Yes, you’re in control of your journey! Subscriptions are flexible, and you can cancel at any time through the app. Your access will continue until the end of your billing cycle, so you’ll never lose out on what you’ve already paid for.",
  }
  ];

  return (
  <>
    <GlobalStyle />
    <ThemeProvider>
    <Section id="help-section" 
      bgColor={"white"}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Box textAlign="center">
          <Typography className={classes.sectionTitle}>Written in the stars: your questions answered</Typography>
        </Box>
        <Box pt={2}>
          {items.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq-panel-${index}`}
              >
                <Typography className={classes.question}>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails id={`faq-panel-${index}`}>
                <Typography className={classes.answer}>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Section>
    </ThemeProvider>
  </>
  );
}

export default HelpSection;
